
import Tabs from '~/components/Tabs.vue'
import Tab from '~/components/Tab.vue'
import AddToCart from '~/components/product/AddToCart.vue'
import ProductVariantSelector from '~/components/product/ProductVariantSelector.vue'
import Keypoints from '~/components/product/Keypoints.vue'
import ThumbnailImageGallery from '~/components/ThumbnailImageGallery.vue'
import KlarnaWidget from '~/components/shop/KlarnaWidget.vue'
import PaypalWidget from '~/components/shop/PaypalWidget.vue'
import CleverHouseBuyButton from '~/components/product/CleverHouseBuyButton.vue'
export default {
  name: 'ProductBuy',
  props: ['document'],
  components: { Tabs, Tab, AddToCart, ProductVariantSelector, Keypoints, ThumbnailImageGallery, KlarnaWidget, PaypalWidget, CleverHouseBuyButton
  },
  data() {
    return {
      isLoading: false,
      product: false
    }
  },
  computed:{
    variants() {
      return this.document.name.includes('Eteria') ? this.document.variants.slice(0, 2) : this.document.variants
    },
    hasPlant(){
      return this.document.name.includes('Natede')
    },
    isTShirt(){
      return this.document.name.includes('T-Shirt')
    },
    isShelfy(){
      return this.document.name.includes('Shelfy')
    },
    country() {
      const country = this.$store.getters.country
      return country?.code
    },
    countryNotHandled() {
      const notHanldedCountries = ['AU', 'NZ']
      return notHanldedCountries.includes(this.country)
    },
    discountPercentage(){
      if (this.product && this.product.price) {
        return Math.round(100 - this.product.price / this.product.compare_at_price * 100)
      } else {
        return 0
      }
    },
    discountAmount(){
      if (this.product && this.product.price) {
        const amount = this.product.compare_at_price - this.product.price
        return this.$options.filters.price(amount, this.product.currency_code)
      } else {
        return false
      }
    }
  },
  methods: {
    setProduct(product){
      this.product = product
      if (!product.autoSelect) {
        this.setSlide(product.sku_code)
      }
    },
    setSlide(id) {
      this.$refs.productGallery?.jumpToSlide(id)
    },
    hasContent(content){
      return content.length && content[0].text?.length
    }
  }
}
