
import Keypoints from '~/components/product/Keypoints.vue'
export default {
  name: 'CleverHouseBuyButton',
  props: ['productName'],
  components: { Keypoints },
  computed: {
    productUrl() {
      const productUrlMap = {
        'Shelfy': 'https://cleverhouseonline.com.au/products/vitesy-shelfy-fridge-purifier?utm_source=vitesy.com',
        'Natede Smart': 'https://cleverhouseonline.com.au/products/vitesy-natede-smart-plant-pot-purifier?utm_source=vitesy.com',
        'Natede': 'https://cleverhouseonline.com.au/products/vitesy-natede-plant-pot-purifier?utm_source=vitesy.com',
        'Eteria': 'https://cleverhouseonline.com.au/products/vitesy-eteria-personal-air-purifier?utm_source=vitesy.com',
      };

      // Find the first matching product name in the map
      for (const [key, url] of Object.entries(productUrlMap)) {
        if (this.productName.includes(key)) {
          return url;
        }
      }

      // Default URL if no match is found
      return 'https://cleverhouseonline.com.au/pages/vitesy?utm_source=vitesy.com';
    }
  }
}
