
export default {
  name: 'KeyPoints',
  props: ['hasPlant', 'isTShirt','hideReturn'],
  computed: {
    country(){
      return this.$store.getters.country
    },
    isIntelligenceAppliances() {
      return this.$config.intelligenceAppliancesEnabled && this.country.code === 'GB'
    },
    yearsOfWarranty() {
      return this.isIntelligenceAppliances ? '5' : '2'
    },
    returnDays() {
      return '14'
    },
    hasPlantIncluded() {
      return this.hasPlant && this.isIntelligenceAppliances
    }
  }
}
